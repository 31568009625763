import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../components/banners/title-banner';
import StartTrialBanner from '../../components/banners/start-trial';
import LoginStore from '../../stores/login-store';

export default function AboutPage(props) {
  let trialBanner = (LoginStore.isLoggedIn()) ? null : <StartTrialBanner {...props} />

  return (
    <div>
      <Helmet>
        <title>Neurochecklists | Site Credits</title>
        <meta name="description" contents="The team behind Neurochecklists" />
      </Helmet>
      <Banner title="Site Credits" />
      <div className="inner-container padded-content-about">
        <div className="pure-g">
          <div className="pure-u-1 pure-u-md-1-2">
            <div className="l-box">
              <p>Data curator: <a href="https://www.linkedin.com/in/ibrahimimam2000/" target="blank">Dr. Ibrahim Imam, FRCP</a></p>
              <p>Development by <a href="https://petermellett.co.uk" target="blank">Peter Mellett</a></p>
              <p>Design by <a href="https://www.infusedmedia.co.uk/" target="blank">Infused Media</a></p>
              <p>Original proof-of-concept by <a href="http://studio14online.co.uk" target="blank">Studio 14</a></p>
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-1-2">
            <div className="l-box">
              <p>Icons made by:</p>
              <ul>
                <li><a href="https://www.freepik.com/" title="Freepik">Freepik</a></li>
                <li><a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a></li>
                <li><a href="https://www.flaticon.com/authors/smartline" title="Smartline">Smartline</a></li>
                <li><a href="https://www.flaticon.com/authors/nikita-golubev" title="Nikita Golubev">Nikita Golubev</a></li>
                <li><a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a></li>
                <li><a href="https://www.flaticon.com/authors/ddara" title="dDara">dDara</a></li>
                <li><a href="https://www.flaticon.com/authors/kiranshastry" title="kiranshastry">kiranshastry</a></li>
              </ul>
              <p>from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>, licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></p>
            </div>
          </div>
        </div>
      </div>
      {trialBanner}
    </div>
  );
}
