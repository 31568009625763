import React from 'react';
import { Link, withRouter, browserHistory } from 'react-router';
import queryString from 'query-string';

import { IconContext } from 'react-icons';
import { AiOutlineClose } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';

import AuthRoutes from '../../routes/logged-in';
import UpgradeButton from '../upgrade/upgrade-button';

import './user-menu.scss';
import SlideoutUserNav from '../navigation/slideout-user-nav';
import routes from '../../routes/routes';
import loginActions from '../../actions/login-actions';

class UserMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            menuOpened: false,
        };
    }

    openMenu() {
        this.setState({
            menuOpened: !this.state.menuOpened,
        });
    }

    handleLogin(location) {
        let query = queryString.stringify({ modal: location });
        browserHistory.replace(`${this.props.location.pathname}?${query}`);
    }

    handleLogout() {
        loginActions.logout(this.props.userApiRequest);
    }

    render() {
        const menuIcon = this.state.menuOpened ? (
            <IconContext.Provider value={{ color: "white" }}>
                <AiOutlineClose />
            </IconContext.Provider>
            ) : <GiHamburgerMenu />;

        return (
            <>
                <ul className="user-menu">
                    {this.props.loggedIn ? (
                        <React.Fragment>
                            <li className="pure-menu-item">
                                <Link to={AuthRoutes.account}>
                                    <span>{`Hi, ${this.props.user.name.split(' ').shift()}`}</span>
                                </Link>
                            </li>
                            <li className="pure-menu-item">
                                <Link to={AuthRoutes.bookmarks}>
                                    <i className="icon-bookmark" />
                                </Link>
                            </li>
                            <li className="pure-menu-item">
                                <a onClick={this.handleLogout.bind(this)} className="logout-btn">
                                    <i className="icon-logout" />
                                </a>
                            </li>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <li className="clickable">
                                <a
                                    id="login-sidebar"
                                    className="flex items-center"
                                    onClick={this.handleLogin.bind(this, 'login')}
                                >
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 16 16"
                                        className=""
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M16 7.992C16 3.58 12.416 0 8 0S0 3.58 0 7.992c0 2.43 1.104 4.62 2.832 6.09.016.016.032.016.032.032.144.112.288.224.448.336.08.048.144.111.224.175A7.98 7.98 0 0 0 8.016 16a7.98 7.98 0 0 0 4.48-1.375c.08-.048.144-.111.224-.16.144-.111.304-.223.448-.335.016-.016.032-.016.032-.032 1.696-1.487 2.8-3.676 2.8-6.106zm-8 7.001c-1.504 0-2.88-.48-4.016-1.279.016-.128.048-.255.08-.383a4.17 4.17 0 0 1 .416-.991c.176-.304.384-.576.64-.816.24-.24.528-.463.816-.639.304-.176.624-.304.976-.4A4.15 4.15 0 0 1 8 10.342a4.185 4.185 0 0 1 2.928 1.166c.368.368.656.8.864 1.295.112.288.192.592.24.911A7.03 7.03 0 0 1 8 14.993zm-2.448-7.4a2.49 2.49 0 0 1-.208-1.024c0-.351.064-.703.208-1.023.144-.32.336-.607.576-.847.24-.24.528-.431.848-.575.32-.144.672-.208 1.024-.208.368 0 .704.064 1.024.208.32.144.608.336.848.575.24.24.432.528.576.847.144.32.208.672.208 1.023 0 .368-.064.704-.208 1.023a2.84 2.84 0 0 1-.576.848 2.84 2.84 0 0 1-.848.575 2.715 2.715 0 0 1-2.064 0 2.84 2.84 0 0 1-.848-.575 2.526 2.526 0 0 1-.56-.848zm7.424 5.306c0-.032-.016-.048-.016-.08a5.22 5.22 0 0 0-.688-1.406 4.883 4.883 0 0 0-1.088-1.135 5.207 5.207 0 0 0-1.04-.608 2.82 2.82 0 0 0 .464-.383 4.2 4.2 0 0 0 .624-.784 3.624 3.624 0 0 0 .528-1.934 3.71 3.71 0 0 0-.288-1.47 3.799 3.799 0 0 0-.816-1.199 3.845 3.845 0 0 0-1.2-.8 3.72 3.72 0 0 0-1.472-.287 3.72 3.72 0 0 0-1.472.288 3.631 3.631 0 0 0-1.2.815 3.84 3.84 0 0 0-.8 1.199 3.71 3.71 0 0 0-.288 1.47c0 .352.048.688.144 1.007.096.336.224.64.4.927.16.288.384.544.624.784.144.144.304.271.48.383a5.12 5.12 0 0 0-1.04.624c-.416.32-.784.703-1.088 1.119a4.999 4.999 0 0 0-.688 1.406c-.016.032-.016.064-.016.08C1.776 11.636.992 9.91.992 7.992.992 4.14 4.144.991 8 .991s7.008 3.149 7.008 7.001a6.96 6.96 0 0 1-2.032 4.907z"></path>
                                    </svg>{' '}
                                    Sign In
                                </a>
                            </li>
                            <li className="clickable">
                                <a
                                    id="register-sidebar"
                                    className="flex items-center"
                                    onClick={this.handleLogin.bind(this, 'register')}
                                >
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 24 24"
                                        className="register-icon"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g id="Circle_Chev_Right">
                                            <g>
                                                <path d="M13.85,11.65a.492.492,0,0,1,0,.7l-3,3a.495.495,0,0,1-.7-.7L12.79,12,10.15,9.35a.495.495,0,0,1,.7-.7Z"></path>
                                                <path d="M12,2.067A9.933,9.933,0,1,1,2.067,12,9.944,9.944,0,0,1,12,2.067Zm0,18.866A8.933,8.933,0,1,0,3.067,12,8.943,8.943,0,0,0,12,20.933Z"></path>
                                            </g>
                                        </g>
                                    </svg>{' '}
                                    Register
                                </a>
                            </li>
                        </React.Fragment>
                    )}
                    <li className="clickable">
                        <a className="menu-btn burger-menu" onClick={this.openMenu.bind(this)}>
                            {menuIcon}
                        </a>
                    </li>
                </ul>

                <SlideoutUserNav {...this.props} menuOpened={this.state.menuOpened} />
            </>
        );
    }
}

export default withRouter(UserMenu);
