import React from 'react';
import { browserHistory } from 'react-router';
import queryString from 'query-string';

export default ({ location }) => {
  const query = queryString.stringify({
    modal: 'register'
  });

  return (
    <div className="contact-us-footer">
      <div className="inner-container">
        <div className="pure-g">
          <div className="pure-u-1">
            <div className="l-box">
              <h4 className="contact-us-footer-title">Start using Neurochecklists today!</h4>
              <button
                onClick={() => browserHistory.replace(`${location.pathname}?${query}`)}
                className="pure-button contact-us-footer-button purple-button">Signup for free</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
