export default [
   {
     title: "What are Neurochecklists?",
     content: "Neurochecklists is a comprehensive database of a diverse range of neurology topics. It is presented in a concise and accessible format making it a convenient way to access relevant and practical information. Each checklist is fully referenced, and all references are linked to their PubMed abstracts. This facilitates further exploration of the topic for work or research."
   },
   {
     title: "How is Neurochecklists set up?",
     content: "The database that constitutes Neurochecklists is made up of 18 specialty chapters, each divided into topics. Each topic is made up of several checklists."
   },
   {
     title: "Who should use Neurochecklists?",
     content: "Neurochecklists is most useful for neurologists, doctors, and other health professionals who manage patients with neurological diseases. Medical students and researchers may also find it useful. It is not aimed at non-medically trained people but the general public may find the simple format useful."
   },
   {
     title: "How do I use Neurochecklists?",
     content: "You could search for any Neurochecklists topic through the search boxes on all pages. This will bring up a search list presented as chapters, topics, or checklists. You may also use Neurochecklists by going through the index and exploring the different chapters or topics."
   },
   {
     title: "Can I use Neurochecklists to treat patients?",
     content: "Neurochecklists is a prompt to guide diagnosis and treatment. It does not make specific treatment recommendations but lists available options and evidence base where available. You should not use Neurochecklists as a drug formulary."
   }
];
