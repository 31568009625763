import React from "react";
import Moment from "moment";

import logo from "../../../images/logo.svg";

export default class NewsItem extends React.Component {
  extractFirstItemInObjectImg(data) {
    if (!data) {
      return logo;
    }
    const key = Object.keys(data)[0];
    const newData = data[key];
    if (newData && newData["URL"]) {
      return `${newData["URL"]}?w=400&h=250`;
    } else {
      return logo;
    }
  }

  reduceExerpt(text, numWords = 14) {
    return text.split(" ").slice(0, numWords).join(" ").replace("[...]", "...");
  }

  render() {
    return (
      <div className="news-article-content bg-grey">
        {this.props.showImage ? (
          <div className="images-holder">
            <img
              className="pure-img"
              src={`${this.extractFirstItemInObjectImg(
                this.props.attachments
              )}`}
            />
          </div>
        ) : null}
        <div className="text-content">
          <div
            className="flex-grow"
            dangerouslySetInnerHTML={{
              __html: this.reduceExerpt(this.props.excerpt, 20) + "...",
            }}
          ></div>
          <footer className="clearfix">
            <span className="published">Published: </span><time className="date" dateTime="">
              {Moment(this.props.date).format("MMMM D, YYYY")}
            </time>
            <br/>
            <div>
              <a className="readmore" target="_blank" href={this.props.URL}>
                Read more
              </a>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
