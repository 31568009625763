import React from 'react';
import AppRoutes from '../../routes/routes';

import images from '../../images';

import './explore-icon.scss';

export default class ExploreIcon extends React.Component {
    render() {
        let { cat } = this.props;

        if (cat.title === 'EXPLORE') {
            cat.icon = require('../../../images/icons/001-category-1.svg');
            cat.link = AppRoutes.explore;
        } else {
            cat.icon = '/images/categories/' + cat._slugs[0] + '.webp';
            if (!cat.icon) {
                cat.icon = images['brain-logo.svg'];
            }
            cat.link = `${AppRoutes.category}${cat._slugs}`;
        }
        return (
            <img
                src={cat.icon}
                alt={cat.title}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = '/images/categories/default.webp';
                }}
                className="explore-option-icon"
            />
        );
    }
}
