import React from 'react';
import classnames from 'classnames';
import uuid from 'uuid/v4';
import { Link } from 'react-router';

import AppStore from '../../stores/stores';
import NewsItem from '../../components/news/news-item';
import VideoSection from './video-section';

import './news-section.scss';
import ChecklistLink from '../../components/checklists/checklist-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faList } from '@fortawesome/free-solid-svg-icons';
import RegisterCTA from '../../components/banners/register-cta';
import loginStore from '../../stores/login-store';
import routes from '../../routes/routes';

export default class NewsSection extends React.Component {
    constructor() {
        super();
        this.state = {
            newsItems: [],
        };

        console.log(this.props);
    }

    componentDidMount() {
        AppStore.getNews({
            onSuccess: ({ data }) => {
                if (data.posts) {
                    this.setState({ newsItems: data.posts });
                }
            },
            onError: function () {},
        });
    }

    renderNewsItem(index, withImage = true) {
        if (this.state.newsItems[index] && Object.keys(this.state.newsItems[index]).length) {
            let newsItemClasses = classnames({
                'news-item': true,
                'hide-mobile': index > 2,
            });

            return (
                <article key={uuid()} className={newsItemClasses}>
                    <NewsItem {...this.state.newsItems[index]} showImage={withImage} />
                </article>
            );
        }
    }

    renderRecentlyPublished(index) {
        if (this.props.recentlyPublished[index] && Object.keys(this.props.recentlyPublished[index]).length) {
            let newsItemClasses = classnames({
                'recently-published-item': true,
            });

            let item = this.props.recentlyPublished[index];

            return (
                <li key={uuid()} className={newsItemClasses}>
                    <FontAwesomeIcon icon={faList} /> <ChecklistLink checklist={item} />
                </li>
            );
        }
    }

    renderPopularCategories(index) {
        if (this.props.popularCategories[index] && Object.keys(this.props.popularCategories[index]).length) {
            let newsItemClasses = classnames({
                'recently-published-item': true,
            });

            let item = this.props.popularCategories[index];

            return (
                <li key={uuid()} className={newsItemClasses}>
                    <FontAwesomeIcon icon={faList} />{' '}
                    <Link to={`${routes.explore}?open=${item._slugs[0]}`}>{item.title}</Link>
                </li>
            );
        }
    }

    render() {
        const newsItemLimit = 8;

        let newsItems = [];
        let recentlyPublished = [];
        let popularCategories = [];

        for (let i = 0; i < newsItemLimit; i++) {
            newsItems.push(this.renderNewsItem(i));
        }
        for (let j = 0; j < this.props.recentlyPublished.length; j++) {
            recentlyPublished.push(this.renderRecentlyPublished(j));
        }
        for (let k = 0; k < this.props.popularCategories.length; k++) {
            popularCategories.push(this.renderPopularCategories(k));
        }

        return (
            <div className="two-up-wrapper">
                <div className="container two-up">
                    <div className="new-section">
                        <h3>Explore Our Publications</h3>
                        <div className="news-grid">
                            {newsItems}

                            <VideoSection video={this.props.video} />
                        </div>

                        {loginStore.isLoggedIn() ? null : <RegisterCTA />}
                    </div>

                    <div className="new-section">
                        <h3>Recently Published</h3>
                        <ul className="recently-published">{recentlyPublished}</ul>

                        <h3>Popular Categories</h3>
                        <ul className="recently-published">{popularCategories}</ul>
                    </div>
                </div>
            </div>
        );
    }
}
