import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import queryString from 'query-string';

class RegisterCTA extends React.Component {
    constructor() {
        super();
        this.handleRegister = this.handleRegister.bind(this);
    }

    handleRegister(e) {
        e.preventDefault();

        let { location } = this.props;
        let query = queryString.stringify({ modal: 'register' });
        browserHistory.replace(`${location.pathname}?${query}`);
    }

    render() {
        return (
            <div className="search-banner-cta">
                <div className="search-banner-cta-inner">
                    <p className="search-banner-cta-text">
                        The Leading Neurology Checklist Database
                    </p>
                    <button className="search-banner-cta-btn purple-button" onClick={this.handleRegister}>
                        Get started
                    </button>
                </div>
            </div>
        );
    }
}
export default withRouter(RegisterCTA);
