import React from 'react';
import MainNavigation from '../navigation/main-navigation';
import TopSearchBar from '../searches/top-searchbar';
import LoginStore from '../../stores/login-store';
import Classnames from 'classnames';
import AuthenticationMixin from '../../mixins/authenticationMixin';
import AppRoutes from '../../routes/routes';

import LogoWithStrapline from './logo-with-strapline';
import UserMenu from './user-menu';

import './header.scss';

class Header extends React.Component {

    render() {
        const searchBar = this.props.showSearchBar ? <TopSearchBar {...this.props} /> : null;

        let loggedIn = LoginStore.isLoggedIn();

        let classes = Classnames({
            'app-header': true,
            'app-header-user': loggedIn,
            'app-header-withsearch': searchBar
        });

        return (
            <header id="app-header" className={classes}>
              <LogoWithStrapline />
              {searchBar}
              <UserMenu loggedIn={loggedIn} user={this.props.user} />
              { this.props.showSearchBar  ? null : <MainNavigation {...this.props} /> }
            </header>
        );
    }
}

export default AuthenticationMixin(Header);
