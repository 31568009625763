import React from 'react';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import { OutboundLink } from 'react-ga';
import classnames from 'classnames';

import AppRoutes from '../../routes/routes';
import SlideOutUserNav from './slideout-user-nav';

import AuthRoutes from '../../routes/logged-in';
import LoginActions from '../../actions/login-actions';
import AuthMixin from '../../mixins/authenticationMixin';
import UpgradeButton from '../upgrade/upgrade-button';
import LoginStore from '../../stores/login-store';
import AppStore from '../../stores/stores';

import './main-navigation.scss';

class MainNavigation extends React.Component {
  constructor() {
    super();
    this.state = {
      menuOpened: false,
      loggedIn: LoginStore.isLoggedIn()
    };
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    AppStore.addChangeListener(this._onChange);
    LoginStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(this._onChange);
    LoginStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState({
      menuOpened: false,
      loggedIn: LoginStore.isLoggedIn()
    });
  }

  selectedItem(location, page) {
    return (location === page ? 'pure-menu-selected' : '');
  }
  
  render() {
    const currentLocation = this.props.location.pathname;

    return (
      <div className="user-navigation pure-menu pure-menu-horizontal">
        <div className="nav-container clearfix">
          <ul className="pure-menu-list">
            <li className="pure-menu-item">
              <Link to={AppRoutes.explore} className={this.selectedItem(currentLocation, AppRoutes.explore)}>
                Explore
              </Link>
            </li>

            {this.state.loggedIn ? (
              <React.Fragment>
                <li className="pure-menu-item">
                  <Link to={AuthRoutes.bookmarks} className={this.selectedItem(currentLocation, AuthRoutes.bookmarks)}>
                    Bookmarks
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link to={AuthRoutes.notes} className={this.selectedItem(currentLocation, AuthRoutes.notes)}>
                    Notes
                  </Link>
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li className="pure-menu-item">
                  <Link className={this.selectedItem(currentLocation, AppRoutes.about)} to={AppRoutes.about}>
                    About
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link className={this.selectedItem(currentLocation, AppRoutes.pricing)} to={AppRoutes.pricing}>
                    Pricing
                  </Link>
                </li>
              </React.Fragment>
            )}

            <li className="pure-menu-item">
              <OutboundLink eventLabel="Blog link" to={AppRoutes.news} target="_blank">The Neurology Lounge Podcast</OutboundLink>
            </li>

            <li className="pure-menu-item">
              <OutboundLink eventLabel="Blog link" to={AppRoutes.paper} target="_blank">Surfing the Brain</OutboundLink>
            </li>

            <li className="pure-menu-item">
              <OutboundLink eventLabel="Book link" to={AppRoutes.book} target="_blank">700 Essential Neurology Checklists</OutboundLink>
            </li>
          </ul>

        </div>
      </div>
    );
  }
}

export default AuthMixin(MainNavigation);
