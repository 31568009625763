import React from 'react';
import { browserHistory } from 'react-router';
import Api from '../../api/api';

import './popup-dialogs.css';

export default class PopupDialog extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (Api.selectorMatches(e.target, '#modal') || Api.selectorMatches(e.target, '.backdrop-container') || Api.selectorMatches(e.target, '.modal-close')) {
      browserHistory.push( window.location.pathname );
    }
  }

  render() {
    return (
      <div id="modal" style={{backgroundColor: this.props.backdropColor || ''}} onClick={this.handleClick}>{this.props.children}</div>
    );
  }
}
