import React from 'react';
import { Link } from 'react-router';
import { OutboundLink } from 'react-ga';
import uuid from 'uuid/v4';
import AppRoutes from '../../routes/routes';
import AppStore from '../../stores/stores';

import './footer.scss';
import Loading from '../loading/loading';

export default class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      popularSearches: [],
      loading: true
    }
  }

  render() {
    return (
      <footer className="page-footer">
        <div className="container">
          <div className="footer-grid">
            <nav className="page-footer--menu">
              <h3>Neurochecklists</h3>
              <ul>
                <li><OutboundLink target="blank" eventLabel="Blog link" to={AppRoutes.news} title="The Neurology Lounge Podcast">The Neurology Lounge Podcast</OutboundLink></li>
                <li><Link to={AppRoutes.pricing}>Pricing</Link></li>
                <li><Link to={AppRoutes.about}>About</Link></li>
                <li><Link to={AppRoutes.faqs}>Faqs</Link></li>
                <li><Link to={AppRoutes.contact}>Contact</Link></li>
              </ul>
            </nav>

            <div className="page-footer--categories tablet-hide">
              <h3>Everything neurology a click away</h3>
              <p>
                Neurochecklists gives you quick and easy access to an extensive range of neurological topics. With an extensive range of checklists across the spectrum
                of neurology, neurochecklists offer relevant and practical information in a clear and concise format. Each checklist is fully referenced and linked to
                updated internet sources or abstracts. Neurochecklists.com simplifies neurological diagnosis and management on the ward or in the clinic.
              </p>
            </div>

            <div className="social-networks">
              <h3>Connect With Us</h3>
              <OutboundLink eventLabel="Facebook" className="social-networks--icon sicon-fb-dark" to={AppRoutes.facebook} target="_blank">Facebook</OutboundLink>
              <OutboundLink eventLabel="Twitter" className="social-networks--icon sicon-twitter-dark" to={AppRoutes.twitter} target="_blank">Twitter</OutboundLink>
              <OutboundLink eventLabel="LinkedIn" className="social-networks--icon sicon-in-dark" to={AppRoutes.linkedin} target="_blank">Linkedin</OutboundLink>
            </div>
          </div>
          <div className="credits">
            <span>&copy; {new Date().getFullYear()} Neurochecklists Ltd. All Rights Reserved.</span>
            <ul>
              <li><Link to={AppRoutes.privacy}>Privacy Policy</Link></li>
              <li><Link to={AppRoutes.data}>Data Policy</Link></li>
              <li><Link to={AppRoutes.tcs}>Terms and Conditions</Link></li>
              <li><Link to={AppRoutes.credits}>Site Credits</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}
