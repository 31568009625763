import React from 'react';
import classnames from 'classnames';
import { SERVER_ERROR } from '../../constants/messages';
import Api from '../../api/api';
import Loading from '../loading/loading';
import Styles from './subscribe.css';

export default class Subscribe extends React.Component {

  constructor(){
    super();
    this.state = {
      success: false,
      error: false,
      loading: false,
      error_message: ''
    };
  }

  handleSubmit(e){
    e.preventDefault();
    const email = this.refs.email.value;

    if(!email) return this.setState({
      error: true,
      success: false,
      error_message: 'Please enter an email address to sign up.'
    });

    this.setState({ loading: true });

    Api.subscribeToNewsLetter({email}, {
      onSuccess: ({data}) => {
        if(data.error_message){
          return this.setState({
            error: true,
            success: false,
            loading: false,
            error_message: data.error_message
          });
        }

        this.setState({
          error: false,
          success: true,
          loading: false,
          error_message: ''
        });

        this.refs.form.reset();
      },
      onError: ({error}) => {
        this.setState({
          error: true,
          success: false,
          error_message: SERVER_ERROR,
          loading: false
        });
      }
    });
  }

  renderForm(e){
    if(this.state.error) message = <p>{this.state.error_message}</p>;
    if(this.state.success) message = <p>Thanks you have been successfully subscribed to our newsletter.</p>

    return (
      <form ref="form" className="newsletter-form" onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="heading">
          Sign up to our news letter to get the latest updates and news
        </h3>
        <div className="message tablet-hide">
          <p>Enter your email below to get the latest Neurochecklists news straight to your inbox</p>
        </div>
        <div className="newsletter-form-inputs">
            <input ref="email" className="pure-input-1" placeholder="Enter your email..." type="email" required />
            <button className="pure-button contact-us-footer-button purple-button" type="submit">Subscribe</button>
        </div>
      </form>
    )
  }

  render(){
    let classes = classnames({
      'app-subscribe': true,
      'success': this.state.success,
      'error': this.state.error
    });

    return (
      <div className={classes}>
        {this.state.loading ? <Loading /> : null }
        <div className="container">
          {this.renderForm()}
        </div>
      </div>
    )
  }

}
