import React from 'react';
import uuid from 'uuid/v4';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import Bookmark from './bookmark-button';
import FreeTrialExpired from '../../components/subcribe/free-trial-expired';

export default class ChecklistHeader extends React.Component {
  constructor() {
    super();
    this.formatDate = this.formatDate.bind(this);
  }

  formatDate() {
    let event = new Date(this.props.last_updated);
    return event.toLocaleDateString();
  }

  supclicked() {
    window.dispatchEvent(new HashChangeEvent("hashchange"));
  }

  render() {
    let parsed_footnotes = '';

    if (this.props.title_footnotes) {
      parsed_footnotes = this.props.title_footnotes.map((footnote, i, array) => {
        const lastItemIndex = array.length - 1;
        const isLast = i === lastItemIndex;
        return (
          <sup key={uuid()}>
            <a onClick={this.supclicked.bind(this)} href={`#s${footnote}`}>
                {footnote}
                {isLast ? '' : ', '}
            </a>
          </sup>
        );
      });
    }

    return (
      <div>
      <FreeTrialExpired/>
      <header className="bg-white checklist-header">
        <div className="inner-container inner-container--wide pure-g">
          <div className="pure-u-1 mobile-show">
            <Breadcrumbs {...this.props} />
          </div>
          <div className="pure-u-1 pure-u-md-18-24">
            <div className="title-box l-box padding-no-top-mobile">
              <h1 className="bold">
                {this.props.title}
              </h1>
              <h2 className="last-updated">Last reviewed on <span className="date">{this.formatDate()}</span></h2>
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-6-24">
            <Bookmark {...this.props} />
          </div>
          <div className="pure-u-1 mobile-hide">
            <Breadcrumbs {...this.props} />
          </div>
        </div>
      </header>
      </div>
    );
  }
}
