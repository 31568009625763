import React from 'react';

export default class VideoSection extends React.Component {
    render() {
        let { video } = this.props;

        return video ? (
            <div className="tablet-hide grid-span-3">
                <div className="news-article-content bg-grey">
                    <div className="text-content">
                        <h2>Find us on YouTube</h2>
                        <a href="https://www.youtube.com/@TheNeurologyLounge-pc5bn">The Neurology Lounge</a>
                        <a href="https://www.youtube.com/@TheNeurologyLounge-pc5bn/shorts">One-minute Lectures</a>
                    </div>
                </div>
            </div>
        ) : null;
    }
}
