import React from 'react';

export default ({ profile_image, name, role, location, comment }) => (
  <div className="pure-u-1 pure-u-md-1-3 review-item">
    <div className="l-box">
      <img className="pure-image" src={profile_image} width="100" height="100" />
      <div className="review-item__details">
        <span className="review-item__name"><strong>{name}</strong></span>
        <span className="review-item__role">{role}</span>
        <span className="review-item__role">{location}</span>
      </div>
      <span className="review-item__comment">"{comment}"</span>
    </div>
  </div>
);
