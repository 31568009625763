import React from 'react';
import uuid from 'uuid/v4';
import Api from '../../api/api';
import Notice from '../../components/notices/notice';
import Loading from '../../components/loading/loading';
import { SERVER_ERROR } from '../../constants/messages';


export default class ContactForm extends React.Component {
  constructor(){
    super();
    this.captcha = Api.captcha();
    this.state = {
      success: false,
      clientError: false,
      serverError: false,
      error_messages: [],
      loading: false
    };
  }

  validateInput(data){
    const error_messages = [];

    if(+data.captcha != this.captcha.result){
      error_messages.push("Your answer was incorrect please try again");
    }

    if(Api.hasEmptyFields(data)){
      error_messages.push('Please fill in all fields correct');
    }

    return error_messages;
  }

  handleSubmit(e){
    e.preventDefault();

    this.setState({ loading: true });

    const name = this.refs.name.value;
    const subject = this.refs.subject.value;
    const email = this.refs.email.value;
    const message = this.refs.message.value;
    const captcha = this.refs.captcha.value;
    const data = {name, email, message};
    const error_messages = this.validateInput(Object.assign({}, data, {captcha}));

    if(error_messages.length){
     return this.setState({
        clientError: true,
        success: false,
        serverError: false,
        loading: false,
        error_messages
      });
    }

    data['subject'] = subject || 'General Query';

    this.sendForm(data);
  }

  sendForm(data){
    Api.sendContact(data, {
      onSuccess: ({data}) => {
        this.refs.form.reset();
        this.setState({
          success: true,
          clientError: false,
          serverError: false,
          loading: false
        });
      },
      onError: () => {
        this.setState({
          serverError: true,
          success: false,
          clientError: false,
          loading: false
        });
      }
    });
  }

  renderClientErrorNotice(){
    let errors = this.state.error_messages.map( (error, index) => {
      return <li key={uuid()}>{error}</li>
    })

    return (
      <Notice alert='alert-danger'>
        <ul>
          {errors}
        </ul>
      </Notice>
    )
  }

  renderServerErrorNotice(){
    return (
      <Notice alert='alert-danger'>
        <p>{SERVER_ERROR}</p>
      </Notice>
    );
  }

  renderSuccessNotice(){
    return (
      <Notice alert='alert-success'>
        <p>Thanks for getting in touch! We will get back to you as soon as possible.</p>
      </Notice>
    );
  }


  render(){
    let notice = null;

    if(this.state.clientError) {
      notice = this.renderClientErrorNotice();
    }

    if(this.state.serverError) {
      notice = this.renderServerErrorNotice();
    }

    if(this.state.success){
      notice = this.renderSuccessNotice();
    }

    return(
      <div className="contact-us-form">
        {(this.state.loading)? <Loading text="Please wait..." /> : null }
        <form ref="form" className="pure-form pure-g" onSubmit={this.handleSubmit.bind(this)}>
          <div className="pure-u-1">
            <h2 className="form-title">Contact form</h2>
          </div>

          <div className="pure-u-1">
            {notice}
          </div>

          <div className="pure-u-1">
            <label>Name <span className="cred">*</span></label>
            <input ref="name" className="pure-input-1 text-input" type="text" name="name" required />
          </div>

          <div className="pure-u-1">
            <label>Subject</label>
            <input ref="subject" className="pure-input-1 text-input" type="text" name="subject" />
          </div>

          <div className="pure-u-1">
            <label>Email <span className="cred">*</span> </label>
            <input ref="email" className="pure-input-1 text-input" type="email" name="email" required />
          </div>

          <div className="pure-u-1">
            <label>Message <span className="cred">*</span></label>
            <textarea ref="message" className="pure-input-1 text-input" name="message" required />
          </div>

          <div className="pure-u-1">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-3-5 challenge">
                <label>To prove that you are a human please answer the following question  <span className="cred">*</span></label>
              </div>
              <div className="pure-u-1 pure-u-md-2-5">
              <input className="pure-input-1 text-input" id="password-confirmation" type="text" ref="captcha"
               placeholder={`What is the sum of ${this.captcha.leftside} + ${this.captcha.rightside}?`} name="captcha" required />
              </div>
            </div>
          </div>

          <div className="pure-u-1">
            <button className="pure-button contact-us-footer-button purple-button" type="submit">Send message</button>
          </div>
        </form>
      </div>
    )
  }
}
