import React from 'react';
import { Helmet } from 'react-helmet';
import SearchBanner from '../../components/banners/search-banner';
import NewsSection from './news-section';
import AppStore from '../../stores/stores';
import Subscribe from '../../components/subcribe/subscribe';
import Testimonials from '../../components/testimonials/index';
import LoginStore from '../../stores/login-store';
import AuthMixin from '../../mixins/authenticationMixin';

import './homepage.scss';

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      recentlyUpdated: [],
      recentSearches: [],
      popularSearches: [],
      popularCategories: [],
      video: false
    };
  }

  componentDidMount() {
    AppStore.loadHomepage(this.props.userApiRequest, {
      onSuccess: ({ data }) => {
        this.setState({
          recentlyUpdated: data.recently_updated,
          recentSearches: data.recent_searches,
          popularSearches: data.popular_searches,
          popularCategories: data.popular_categories,
          video: data.video
        });
      },
      onError: () => {}
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="description" contents="Neurochecklists gives you quick and easy access to an extensive range of neurological topics. With an extensive range of checklists across the spectrum                             of neurology, neurochecklists offer relevant and practical information in a clear and concise format. Each checklist is fully referenced and linked to                             updated internet sources or abstracts. Neurochecklists.com simplifies neurological diagnosis and management on the ward or in the clinic."></meta>
          <link rel="canonical" href={'https://neurochecklists.com'} />
        </Helmet>

        <SearchBanner {...this.props} {...this.state} />

        <NewsSection popularCategories={this.state.popularCategories} newsItems={this.state.newsItems}  video={this.state.video} recentlyPublished={this.state.recentlyUpdated}/>

        {LoginStore.isLoggedIn() ? '' : <Testimonials />}
        <Subscribe />
      </div>
    );
  }
}

export default AuthMixin(HomePage);
