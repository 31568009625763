import React from "react";
import { Link } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

import AppRoutes from "../../routes/routes";

import "./checklist-link.scss";

export default class ChecklistLink extends React.Component {
  render() {
    let { checklist } = this.props;
    return (
      <div className="checklist-link">
        <Link to={`${AppRoutes.checklists}${checklist._slugs}`}>
          {checklist.title}
        </Link>
        {checklist.has_note ? (
          <span className="notes-icon">
            <FontAwesomeIcon icon={faFileAlt} />
          </span>
        ) : null}
      </div>
    );
  }
}
