const API_ROOT = `${process.env.API_URL}`;
const API_DOMAIN = `${process.env.API_DOMAIN}`;

export default {
  LOGIN: `${API_ROOT}/users/sign_in`,
  LOGOUT: `${API_ROOT}/users/sign_out`,
  REGISTER: `${API_ROOT}/users`,
  FORGOTPASSWORD: `${API_ROOT}/user_password_reset`,
  PASSWORDRESET: `${API_ROOT}/user_password_reset/reset_password`,
  VERIFY_ACCOUNT: `${API_ROOT}/users/confirmation?confirmation_token=`,
  PROFILES: `${API_ROOT}/profiles`,
  PASSWORDUPDATE: `${API_ROOT}/profiles/update_password`,
  DELETEACCOUNT: `${API_ROOT}/delete_account`,
  DOWNLOADACCOUNT: `${API_ROOT}/account/download`,
  PARTS: `${API_ROOT}/parts/explore`,
  RANDOM_PARTS: `${API_ROOT}/parts/random`,
  CHAPTERS: `${API_ROOT}/chapters`,
  TOPICS: `${API_ROOT}/topics`,
  CHECKLISTS: `${API_ROOT}/checklists`,
  SEARCH: `${API_ROOT}/search`,
  AUTOCOMPLETE: `${API_ROOT}/autocomplete`,
  CHECKLISTVIEW:`${API_ROOT}/checklists_search`,
  RECENTSEARCHES: `${API_ROOT}/checklists_search`,
  CONTEXT: `${API_ROOT}/context`,
  CHECKOUT: `${API_ROOT}/checkout`,
  PLANS: `${API_ROOT}/plan`,
  BOOKMARKS: `${API_ROOT}/bookmarks`,
  FEEDBACK: `${API_ROOT}/feedback`,
  CONTACT: `${API_ROOT}/contact`,
  SUBSCRIBE: `${API_ROOT}/subscribe`,
  DOWNLOAD_TOKEN: `${API_ROOT}/download/token`,
  DOWNLOAD_POWERPOINT: `${API_ROOT}/checklists/:id/download/powerpoint`,
  NOTE: `${API_ROOT}/checklists/:id/note`,
  HOMEPAGE: `${API_ROOT}/homepage?recent=10`,
  FOOTER: `${API_ROOT}/footer?popular=10`,
  NOTES: `${API_ROOT}/notes`,
  PROMO: `${API_ROOT}/promo/check`,
  VALIDATE_EMAIL: `${API_ROOT}/email/verify`,
  NEWS: "https://public-api.wordpress.com/rest/v1.1/sites/theneurologylounge.com/posts/?number=6&fields=URL,excerpt,attachments,date",
  SITEMAP: `${API_DOMAIN}/sitemap.xml`
}
