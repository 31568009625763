import React from 'react';
import { Link } from 'react-router';
import Classnames from 'classnames';
import AppAuthRoutes from '../../routes/logged-in';
import LoginStore from '../../stores/login-store';
import AppRoutes from '../../routes/routes';
import LoginActions from '../../actions/login-actions';
import UpgradeButton from '../upgrade/upgrade-button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { browserHistory } from 'react-router';
import queryString from 'query-string';

import './slideout-nav.scss';
import { OutboundLink } from 'react-ga';

export default class SlideoutUserNav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            loggedIn: LoginStore.isLoggedIn(),
        };
    }

    handleLogin(location) {
        let query = queryString.stringify({ modal: location });
        browserHistory.replace(`${this.props.location.pathname}?${query}`);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loggedIn: LoginStore.isLoggedIn(),
            ...nextProps,
        });
    }

    render() {
        let classes = Classnames({
            'slide-out-navigation--open': this.state.menuOpened,
            'slide-out-navigation': true,
            'slide-out-navigation-user': true,
        });

        let userApiRequest = {
            'X-USER-EMAIL': this.state.user.email,
            'X-USER-TOKEN': this.state.user.authentication_token,
        };

        return (
            <div className={classes}>
                <nav>
                    {this.state.loggedIn ? (
                        <div className="nav-section">
                            {LoginStore.getSessionUserData().account_type === 'Starter' ? (
                                <UpgradeButton {...this.props} />
                            ) : (
                                ''
                            )}
                            <ul className="mobile-show">
                                <li>
                                    <span>{`Hi, ${this.state.user.name}`}</span>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <div className="nav-section flex">
                            <div className="upgrade-btn">
                                <a href="#login" id="login-sidebar" onClick={this.handleLogin.bind(this, 'login')}>
                                    Login
                                </a>
                            </div>

                            <div className="upgrade-btn">
                                <a
                                    href="#register"
                                    id="register-sidebar"
                                    onClick={this.handleLogin.bind(this, 'register')}
                                >
                                    Register
                                </a>
                            </div>
                        </div>
                    )}

                    <div className="nav-section">
                        <ul>
                            <li>
                                <Link to={AppAuthRoutes.dashboard} activeClassName="active-page">
                                    Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.explore} activeClassName="active-page">
                                    Explore
                                </Link>
                            </li>
                            <li>
                                <Link to={AppAuthRoutes.bookmarks} activeClassName="active-page">
                                    Bookmarks{' '}
                                    {this.state.loggedIn ? '' : <FontAwesomeIcon className="right" icon={faLock} />}
                                </Link>
                            </li>
                            <li>
                                <Link to={AppAuthRoutes.notes} activeClassName="active-page">
                                    Notes{' '}
                                    {this.state.loggedIn ? '' : <FontAwesomeIcon className="right" icon={faLock} />}
                                </Link>
                            </li>
                            <li>
                                <OutboundLink eventLabel="Blog link" to={AppRoutes.news} target="_blank">
                                    The Neurology Lounge Podcast
                                </OutboundLink>
                            </li>
                        </ul>
                    </div>

                    {this.state.loggedIn ? (
                        <div className="nav-section">
                            <h3>My Account</h3>
                            <ul>
                                <li>
                                    <Link to={AppAuthRoutes.account}>Account</Link>
                                </li>
                                <li>
                                    <Link to={AppAuthRoutes.guide}>Guide</Link>
                                </li>
                                <li>
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        onClick={e => {
                                            e.preventDefault();
                                            LoginActions.logout(userApiRequest);
                                        }}
                                    >
                                        Sign Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="nav-section">
                        <h3>Company</h3>
                        <ul>
                            <li>
                                <Link to={AppRoutes.about}>About us</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.faqs}>FAQs</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.pricing}>Pricing</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.privacy}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.data}>Data Policy</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.tcs}>Terms &amp; Conditions</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.credits}>Site Credits</Link>
                            </li>
                            <li>
                                <Link to={AppRoutes.contact}>Contact Us</Link>
                            </li>

                            <li>
                                <OutboundLink eventLabel="Facebook" to={AppRoutes.facebook} target="_blank">
                                    Facebook
                                </OutboundLink>
                            </li>
                            <li>
                                <OutboundLink eventLabel="Twitter" to={AppRoutes.twitter} target="_blank">
                                    Twitter
                                </OutboundLink>
                            </li>
                            <li>
                                <OutboundLink eventLabel="LinkedIn" to={AppRoutes.linkedin} target="_blank">
                                    Linkedin
                                </OutboundLink>
                            </li>
                        </ul>
                    </div>

                    <div className="nav-section">
                        <h3>Media</h3>
                        <ul>
                            <li>
                                <OutboundLink eventLabel="Book link" to={AppRoutes.book} target="_blank">
                                    700 Essential Neurology Checklists
                                </OutboundLink>
                            </li>

                            <li className="pure-menu-item">
                                <OutboundLink eventLabel="Blog link" to={AppRoutes.news} target="_blank">
                                    The Neurology Lounge Podcast
                                </OutboundLink>
                            </li>
                            <li className="pure-menu-item">
                                <OutboundLink eventLabel="Blog link" to={AppRoutes.paper} target="_blank">
                                    Surfing the Brain
                                </OutboundLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}
