import React from 'react';
import Testimonial from './testimonial';

import './testimonials.css';

const Data = [
    {
        "name": "Alakendu Sarkar",
        "role": "Lead Consultant Stroke Neurologist",
        "location": "The Walton Center, Liverpool",
        "profile_image": require("../../../images/testamonials/alakendu_sarkar.png"),
        "comment": "This tool is wonderful and I found it very user-friendly"
    },
    {
        "name": "Ahjoku Amadi-Obi",
        "role": "Research Fellow (Telemedicine)",
        "location": "Royal College of Surgeons of Ireland",
        "profile_image": require("../../../images/testamonials/ahjoku_amadi_obi.png"),
        "comment": "I find this an excellent resource for clinicians"
    },
    {
        "name": "Dr. Regan Solomons",
        "role": "Paediatric Neurologist",
        "location": "University of Stellenbosch",
        "profile_image": require("../../../images/testamonials/regan_solomons.png"),
        "comment": "The app is good. It is concise and will be easy to access"
    }
]

export default () => (
    <div className="testimonials-container">
        <div className="container">
            <div className="reviews">
                <h3>What people are saying about Neurochecklists</h3>
                {Data.map( (item, index) => <Testimonial {...item} key={index} /> )}
            </div>
        </div>
    </div>
)

