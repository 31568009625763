import React from 'react';
import './logo-with-strapline.scss';
import { withRouter, Link } from 'react-router';

import AppRoutes from '../../routes/routes';

class Logo extends React.Component {
    constructor() {
        super();
    }

    render() {
        let shouldLink = this.props.location.pathname !== AppRoutes.homepage;
        let content = (
            <div className="logo">
                <img className="logo-img" src={require('../../../images/logo-white.svg')} />
            </div>
        );
        return shouldLink ? <Link to={AppRoutes.homepage}>{content}</Link> : content;
    }
}

export default withRouter(Logo);
