import React from 'react';
import uuid from 'uuid/v4';
import faqData from './faqs-data';
import { Link } from 'react-router';
import classNames from 'classnames';

class ContactFaqs extends React.Component {
    render() {
        const chunkArray = (arr, size) =>
            arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr];

        let chunked = chunkArray(faqData, 3);
        let faqs = [];
        chunked.forEach(arr => {
            let faqItems = arr.map((item, index) => {
                return <FaqItem key={uuid()} item={item} />;
            });
            faqs.push(<div className="faq-flex">{faqItems}</div>);
        });
        return (
          <>
            <div className="faqs-title-section">
                <div className="l-box">
                    <p>Here are some of our most frequently asked questions.</p>
                    <p>
                        If you have unanswered questions then we'd like to hear them. Please{' '}
                        <Link to="/contact">contact us</Link>
                    </p>
                </div>
            </div>
            <div className="faqs-columns">
                {faqs}
            </div>
          </>
        );
    }
}

export default ContactFaqs;

class FaqItem extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        let classes = classNames({
            'faq-item-closed': !this.state.open,
            'faq-item': true,
            'clearfix': true,
        });

        let { item } = this.props;

        return (
            <div className={classes} onClick={this.onClick}>
                <div className="faq-item-container">
                    <h2 className="faq-title">
                        {item.title}
                        {this.state.open ? <i className="icon-minus" /> : <i className="icon-plus" />}
                    </h2>
                    <p className="faq-content">{item.content}</p>
                </div>
            </div>
        );
    }
}
